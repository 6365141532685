import React, { useEffect, useState } from "react";
import right_arrow from "../../assets/images/svg/arrow-right.svg";
import CustomCKEditor from "../general/CustomCKEditor";
import { useLocation, useNavigate } from "react-router-dom";
import InformationContent from "../informationContent";
import BackToAction from "../general/backToAction";
import CustomInputColumn from "../general/customInputColumn";
import leo from "leo-profanity";
import WarningModal from "../general/warningModal";
import ErrorMsgModal from "../general/errorMsgModal";
import { getData, setData } from "../../utils/storage";
import GeneratedOption from "./generatedOption";
import CustomCheckBox from "../general/customCheckbox";
import { templeteData } from "../../data/templeteData";
import { object } from "yup";
import ErrorMessage from "../errorMessage";

const LetterContainer = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const curDate =
		new Date().getDate() +
		"/" +
		(new Date().getMonth() + 1) +
		"/" +
		new Date().getFullYear();
	const [letterData, setLetterData] = useState(
		location.state !== null && location.state.letterBuilderKey !== undefined
			? templeteData[location.state.letterBuilderKey]
			: null
	); // get data for the letter builder from the templeteData
	const [topDescription, setTopDescription] = useState(
		location.state !== null && location.state.letterBuilderKey !== undefined
			? templeteData[location.state.letterBuilderKey]?.description
			: null
	);
	const [letterInformation, setLetterInformation] = useState(
		location.state !== null && location.state.letterBuilderKey !== undefined
			? templeteData[location.state.letterBuilderKey]?.builderInfo
			: null
	);
	const [letterInformationBottom, setLetterInformationBottom] = useState(
		location.state !== null && location.state.letterBuilderKey !== undefined
			? templeteData[location.state.letterBuilderKey]?.builderInfoBottom
			: null
	);
	const [generatedInformation, setGeneratedInformation] = useState(
		location.state !== null && location.state.letterBuilderKey !== undefined
			? templeteData[location.state.letterBuilderKey]?.generatedLetterInfo
			: null
	);
	const [maxLeftIndex, setMaxLeftIndex] = useState(
		location.state !== null && location.state.letterBuilderKey !== undefined
			? templeteData[location.state.letterBuilderKey]?.maxLeftIndex
			: null
	);
	const [warning, setWarning] = useState(
		location.state !== null && location.state.letterBuilderKey !== undefined
			? templeteData[location.state.letterBuilderKey]?.warning
			: null
	);
	// this will ocntain the data for the letter builder input fields to render on the page
	const _letterBuildData = letterData?.inputFields;
	const _letterTemplate = letterData?.letterContaint;
	const [letterBuildData, setLetterBuilderData] = useState([]);

	const [isHorizontalView, setHorizontalView] = useState("");
	const [showProfanityAlert, setShowProfanityAlert] = useState(false);
	const [allFieldEntered, setAllFieldEntered] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [showError, setShowError] = useState(false);
	const [checkedAnswer, setCheckedAnswer] = useState([]);
	const [actionTitle,setActionTitle] = useState(
		location.state !== null && location.state.actionTitle !== undefined
			? location.state.actionTitle
			: ""
	)
	useEffect(() => {
		getData("generatedKey").then((res) => {
			if (res !== "") {
				setLetterData(templeteData[res]); // get data for the letter builder from the templeteData
				setTopDescription(templeteData[res]?.description);
				setLetterInformation(templeteData[res]?.builderInfo);
				setGeneratedInformation(templeteData[res]?.generatedLetterInfo);
				setMaxLeftIndex(templeteData[res]?.maxLeftIndex);
				setWarning(templeteData[res]?.warning);
				setLetterInformationBottom(templeteData[res]?.builderInfoBottom);
			}
			getData("actionTitle").then((res)=>{
				if(res !== "")
				{
					setActionTitle(res);
				}
			});
		});
		
	}, []);

	useEffect(() => {
		if (location.state !== null && location.state.path === "letter-generated") {
			getData("letterBuild").then((res) => {
				if (res !== undefined) {
					setLetterBuilderData(res);
				}
			});
		}

		setLetterBuilderData(
			_letterBuildData?.sort((a, b) => a.position - b.position)
		);
	}, [letterData]);

	useEffect(() => {}, [showProfanityAlert, allFieldEntered]);

	const clearAll = () => {
		setLetterBuilderData((prevInputs) =>
			prevInputs.map((input) => ({ ...input, value: "" }))
		);
		setCheckedAnswer("");
	};

	const nextScreeen = () => {
		// check all fields are fields anf also check for profinaty strings
		let _profinatyCheck = false;
		let _allFieldEntered = true;
		let updatedData = letterBuildData?.map((obj) => {
			let inputValue =
				obj.type == "ck-editor"
					? leo.check(obj.value.replace(/<[^>]*>/g, ""))
					: leo.check(obj.value);
			// Regular expression to match uppercase words with only alphabets and more than one letter
			const uppercaseWordRegex = /\b[A-Z]{2,}\b/;
			const containsUppercaseWord =
				obj.placeholderTxt === "AB12 123"
					? false
					: uppercaseWordRegex.test(obj.value);
			if (obj.value == "") {
				_allFieldEntered = false;
				return { ...obj, isValid: false };
			}
			if (inputValue) {
				_profinatyCheck = true;
				return { ...obj, isValid: false };
			}
			if (containsUppercaseWord) {
				_profinatyCheck = true;
				return { ...obj, isValid: false };
			}
			return { ...obj, isValid: true };
		});
		setLetterBuilderData(updatedData);
		setShowProfanityAlert(_profinatyCheck);

		if (!_allFieldEntered && _profinatyCheck) {
			setShowError(true);
		} else if (_profinatyCheck) {
			setShowModal(true);
		}

		// if(_profinatyCheck)
		if (!_allFieldEntered && !_profinatyCheck) setShowError(true);

		if (!_profinatyCheck && _allFieldEntered) onSubmit();
	};

	const onSubmit = (e) => {
		setData("letterBuild", letterBuildData);
		navigate("/get-advice/generated-letter", {
			state: {
				letterBuild: letterBuildData,
				letterTemplate: _letterTemplate,
				information: generatedInformation,
				maxLeftIndex: maxLeftIndex,
			},
		});
	};

	const onClose = (e) => {
		setShowModal(false);
		setShowError(false);
	};

	const handleInputChange = (value, id) => {
		// this method is used to replace the white background color with the off white color for jodit editor
		let temp = value.replace(
			/background-color:\s*rgb\(255,\s*255,\s*255\)/g,
			"background-color: rgb(250, 250, 250)"
		);
		if(temp === '<p><br></p>')
			temp = '';
		setLetterBuilderData((prevInputs) =>
			prevInputs.map((input) =>
				input.position === id
					? { ...input, value: temp, isValid: temp != ""  }
					: input
			)
		);
	};

	useEffect(() => {}, [letterBuildData]);

	const handleResponse = (id, value, selected) => {
		handleInputChange(value, id);

		setCheckedAnswer(value);
	};

	return (
		<div>
			<div className="bg-cream letterBuilderPage">
				<div className="container bg-white">
					<BackToAction link={"/get-advice/action-plan"} />
					<div className="content-container col-12 col-md-12">
						<h3 className="title-26 black">Letter builder</h3>
						<p
							className="text-normal black letter-description-container"
							dangerouslySetInnerHTML={{ __html: topDescription }}
						/>
						{warning ? (
							<div className="mb-3">
								<ErrorMessage
									message="If you are in immediate danger you should contact the police on 999."
									link=""
								/>
							</div>
						) : null}
						<InformationContent
							title={letterInformation?.title}
							description={letterInformation?.description}
						/>
						<div className="letterEditor-container row">
							<p className="text-tittle-19 mb-0 mandatory">
								{"All fields are mandatory."}
							</p>
							<div className="letterEditor-content col-12 col-md-6 ">
								{letterBuildData
									?.filter(
										(obj) =>
											obj.type !== "ck-editor" && obj.type !== "ck-checkbox"
									)
									.map((data, index) =>
										data.type !== "checkbox" ? (
											<CustomInputColumn
												_key={index}
												label={data.title}
												short_descp={data.short_descp}
												type={data.type}
												value={data.value}
												onChange={(e) => handleInputChange(e, data.position)}
												_className={index > 0 ? "textStyle" : ""}
												placeholder={data.placeholderTxt}
												isValid={data.isValid}
												toolTip={data.toolTip}
											/>
										) : (
											<>
												{" "}
												<p className="text-tittle-19 subTextStyle mt-5">
													{data.title}
												</p>
												<p
													className="text-16 editor-description"
													dangerouslySetInnerHTML={{ __html: data.short_descp }}
												/>
												{/* <CustomCKEditor
                    placeholder={data.value !== "" ? '' : data.placeholderTxt}
                    value={data.value}
                    onChange={(_data) => handleInputChange(_data, data.position)}
                    isValid={data.isValid}
                  /> */}
												{data.option.map((item, index) => {
													return (
														<CustomCheckBox
															key={1}
															type="question"
															selected={
																checkedAnswer.includes(item.optionvalue)
																	? true
																	: data.value == item.optionvalue
																	? true
																	: false
															}
															label={item.optionvalue}
															value={item.optionvalue}
															onClick={() =>
																handleResponse(
																	data.position,
																	item.optionvalue,
																	item.selected
																)
															}
														/>
													);
												})}
											</>
										)
									)}
							</div>

							{Array.isArray(_letterTemplate) && _letterTemplate.length > 0 ? (
								<GeneratedOption
									type="letting-agent"
									letterTemplate={_letterTemplate}
									letterBuildData={letterBuildData}
									isHorizontal={isHorizontalView}
									maxLeftIndex={maxLeftIndex}
									actionTitle={actionTitle}
								/>
							) : null}
							<div className="bottomContiner">
								{letterBuildData
									?.filter((obj) => obj.type == "ck-editor")
									.map((data, index) => (
										<>
											<p className="text-tittle-19 subTextStyle">
												{data.title}
											</p>
											<p
												className="text-16 editor-description"
												dangerouslySetInnerHTML={{ __html: data.short_descp }}
											/>
											<CustomCKEditor
												placeholder={
													data.value !== "" ? "" : data.placeholderTxt
												}
												value={data.value}
												onChange={(_data) =>
													handleInputChange(_data, data.position)
												}
												isValid={data.isValid}
											/>
										</>
									))}
							</div>
							<div className="bottomContiner">
								{letterBuildData
									?.filter((obj) => obj.type == "ck-checkbox")
									.map((data, index) => (
										<>
											<p className="text-tittle-19 subTextStyle">
												{data.title}
											</p>
											<p
												className="text-16 editor-description"
												dangerouslySetInnerHTML={{ __html: data.short_descp }}
											/>

											{data.option.map((item, index) => {
												return (
													<CustomCheckBox
														key={1}
														type="question"
														selected={
															checkedAnswer.includes(item.optionvalue)
																? true
																: data.value == item.optionvalue
																? true
																: false
														}
														label={item.optionvalue}
														value={item.optionvalue}
														onClick={() =>
															handleResponse(
																data.position,
																item.optionvalue,
																item.selected
															)
														}
													/>
												);
											})}
										</>
									))}
							</div>
							{letterInformationBottom !==  undefined && letterInformationBottom !== '' ?<div className='d-block mt-3'> <InformationContent
							title={letterInformationBottom?.title}
							description={letterInformationBottom?.description}
						/> </div> : null}
							<div className="d-flex justify-content-between buttonContainer">
								<button className="discardButton" onClick={() => clearAll()}>
									Clear all
								</button>
								<button
									className="generateButton"
									onClick={() => nextScreeen()}
								>
									Generate letter
									<img
										src={right_arrow}
										style={{
											height: "2.563rem",
											width: "2.563rem",
											marginLeft: "1.5rem",
											verticalAlign: "middle",
										}}
									/>
								</button>
							</div>
						</div>
					</div>
				</div>

				<WarningModal
					title="Warning message"
					show={showModal}
					buttonText="I agree, generate my letter"
					onClose={(e) => onClose(e)}
					onSubmit={(e) => onSubmit(e)}
				/>

				<ErrorMsgModal
					title="Please complete all fields"
					show={showError}
					msg="In order to generate a letter or telephone script, you must complete all of the mandatory fields. The fields you have missed are highlighted in red."
					onClose={(e) => onClose(e)}
				/>
			</div>
		</div>
	);
};

export default LetterContainer;
