import React from "react";

const GeneratedOption = (props) => {
	console.log(" generatedOptions called;-",props)
	return (
		<>
			<div className="letterEditor-viewer col-0 col-md-6 d-none d-md-block">
				{props.letterTemplate.map((data, index) => {
					let _value = props.letterBuildData.filter(
						(obj) => obj.title == data.valueOF
					);
					console.log("data inside isDynamicTitle:-",data?.isDynamicTitle," data inside actionTitle:-",props.actionTitle)
						
					let _isHorizontalView =
						data.hasOwnProperty("isHorizontal") && data.isHorizontal
							? true
							: false;

					if (
						index < props.maxLeftIndex &&
						data.alignment !== "left" &&
						data.alignment !== "side-right"
					) {
						return (
							<div className="d-flex justify-content-between mb-2">
								<p className="text-14 col-6">{data?.isDynamicTitle ? data.title+" " + props.actionTitle: data.title }</p>
								{_value.length > 0 ? (
									_value[0].value == "" ? (
										<h3
											className="text-14-26 highlight mb-0 text-center bg-white"
											style={{ textAlign: "right", marginTop: 0 }}
										>
											{data.default}
										</h3>
									) : (
										<h3
											className="text-14-26 text-right col-5 "
											style={{
												textAlign: "right",
												marginTop: 0,
												minWidth: "9.375rem",
												marginBottom: 0,
												paddingBottom: 0,
											}}
										>
											{_value[0].value}
										</h3>
									)
								) : data.valueOF == "Date" ? (
									<h3
										className="text-14-26 text-right col-3 "
										style={{
											textAlign: "right",
											marginTop: 0,
											marginBottom: 0,
											paddingBottom: 0,
										}}
									>
										{data.default}
									</h3>
								) : null}
							</div>
						);
					} else {
						if (data?.default2 != undefined && data?.default2 !== "") {
							let _value2 = props.letterBuildData.filter(
								(obj) => obj.title == data?.valueOF2
							);
							let _value1 = props.letterBuildData.filter(
								(obj) => obj.title == data?.valueOF1
							);

							return (
								<div className="align-items-start mt-3 mb-3">
									<p
										className="text-14 mb-0"
										style={{ fontWeight: 400, wordBreak: "break-word" }}
									>
										{data.leftdescription}
										{_value.length > 0 ? (
											_value[0].value == "" ? (
												<h3 className="text-14-26 d-inline-block highlight ml-2 mr-2 text-center bg-white mb-0">
													{data.default}
												</h3>
											) : _value[0].type === "ck-editor" ? (
												<strong
													className="text-14-26 d-inline-block text-right ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
													dangerouslySetInnerHTML={{ __html: _value[0].value }}
												/>
											) : (
												<strong
													className="text-14-26 d-inline-block text-right  ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
												>
													{_value[0].value}
												</strong>
											)
										) : data.valueOF === "Date" ? (
											<strong className="text-14-26 ml-2 d-inline-block text-right col-3">
												{data.default}
											</strong>
										) : null}
										{data?.rightdescription}
										{data?.leftdescription1}

										{_value1.length > 0 ? (
											_value1[0].value == "" ? (
												<h3 className="text-14-26 d-inline-block highlight ml-2 text-center bg-white mb-0">
													{data.default1}
												</h3>
											) : _value1[0].type === "ck-editor" ? (
												<strong
													className="text-14-26 d-inline-block text-right ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
													dangerouslySetInnerHTML={{ __html: _value1[0].value }}
												/>
											) : (
												<strong
													className="text-14-26 d-inline-block text-right  ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
												>
													{_value1[0].value}
												</strong>
											)
										) : data?.valueOF1 === "Date" ? (
											<strong className="text-14-26 ml-2 d-inline-block text-right col-3">
												{data?.default1}
											</strong>
										) : null}
										{data?.rightdescription1}
										{data?.leftdescription2}
										{_value2.length > 0 ? (
											_value2[0].value == "" ? (
												<h3 className="text-14-26 d-inline-block highlight ml-2 text-center bg-white mb-0">
													{data?.default2}
												</h3>
											) : _value2[0].type === "ck-editor" ? (
												<strong
													className="text-14-26 d-inline-block text-right ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
													dangerouslySetInnerHTML={{ __html: _value2[0].value }}
												/>
											) : (
												<strong
													className="text-14-26 d-inline-block text-right  ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
												>
													{_value2[0].value}
												</strong>
											)
										) : data?.valueOF2 === "Date" ? (
											<strong className="text-14-26 ml-2 d-inline-block text-right col-3">
												{data.default2}
											</strong>
										) : null}
										{data?.rightdescription2}
									</p>
								</div>
							);
						} 
						else if (data.default1 !== "") {
							let _value1 = props.letterBuildData.filter(
								(obj) => obj.title == data.valueOF1
							);

							return (
								<div className="align-items-start mt-3 mb-3">
									<p
										className="text-14 mb-0"
										style={{ fontWeight: 400, wordBreak: "break-word" }}
									>
										{data.leftdescription}
										{_value.length > 0 ? (
											_value[0].value == "" ? (
												<h3 className="text-14-26 d-inline-block highlight ml-2 mr-2 text-center bg-white mb-0">
													{data.default}
												</h3>
											) : _value[0].type === "ck-editor" ? (
												<strong
													className="text-14-26 d-inline-block text-right ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
													dangerouslySetInnerHTML={{ __html: _value[0].value }}
												/>
											) : (
												<strong
													className="text-14-26 d-inline-block text-right  ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
												>
													{_value[0].value}
												</strong>
											)
										) : data.valueOF === "Date" ? (
											<strong className="text-14-26 ml-2 d-inline-block text-right col-3">
												{data.default}
											</strong>
										) : null}
										{data.rightdescription}
										{data.leftdescription1}

										{_value1.length > 0 ? (
											_value1[0].value == "" ? (
												<h3 className="text-14-26 d-inline-block highlight ml-2 text-center bg-white mb-0">
													{data.default1}
												</h3>
											) : _value1[0].type === "ck-editor" ? (
												<strong
													className="text-14-26 d-inline-block text-right ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
													dangerouslySetInnerHTML={{ __html: _value1[0].value }}
												/>
											) : (
												<strong
													className="text-14-26 d-inline-block text-right  ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
												>
													{_value1[0].value}
												</strong>
											)
										) : data.valueOF1 === "Date" ? (
											<strong className="text-14-26 ml-2 d-inline-block text-right col-3">
												{data.default1}
											</strong>
										) : null}
										{data.rightdescription1}
									</p>
								</div>
							);
						} else if (data.alignment === "side-right") {
							return (
								<div className="align-items-start mt-3 mb-3">
									<p className="text-14 mb-0" style={{ fontWeight: 400 }}>
										{data?.isDynamicTitle ?  data.leftdescription+ " "+ props.actionTitle: data.leftdescription}
										{_value.length > 0 ? (
											_value[0].value == "" ? (
												<h3 className="text-14-26 d-inline-block highlight ml-2 text-center bg-white mb-0">
													{data.default}
												</h3>
											) : _value[0].type === "ck-editor" ? (
												<strong
													className="text-14-26 d-inline-block text-right ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginTop: 0,
														marginBottom: 0,
													}}
													dangerouslySetInnerHTML={{ __html: _value[0].value }}
												/>
											) : (
												<strong
													className="text-14-26 d-inline-block text-right  ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
												>
													{_value[0].value}
												</strong>
											)
										) : data.valueOF === "Date" ? (
											<strong className="text-14-26 ml-2 d-inline-block text-right col-3">
												{data.default}
											</strong>
										) : null}
									</p>
									{data.rightdescription !== "" ? (
										<p
											className="text-14 mb-0 d-flex"
											style={{ fontWeight: 400 }}
										>
											{data.rightdescription}
										</p>
									) : null}
								</div>
							);
						} else if (data.alignment === "center") {
							return (
								<div className="align-items-start mt-3 mb-3">
									<p className="text-14 mb-0" style={{ fontWeight: 400 }}>
										{data.leftdescription}
										{_value.length > 0 ? (
											_value[0].value == "" ? (
												<h3 className="text-14-26 d-inline-block highlight ml-2 text-center bg-white">
													{data.default}
												</h3>
											) : _value[0].type === "ck-editor" ? (
												<strong
													className="text-14-26 d-inline-block text-right ml-2"
													style={{ lineHeight: "unset", marginLeft: "0.1rem" }}
													dangerouslySetInnerHTML={{ __html: _value[0].value }}
												/>
											) : (
												<strong
													className="text-14-26 d-inline-block text-right  ml-2"
													style={{
														lineHeight: "unset",
														marginLeft: "0.1rem",
														marginRight: "0.1rem",
														paddingBottom: 0,
														marginBottom: 0,
														marginTop: 0,
													}}
												>
													{_value[0].value}
												</strong>
											)
										) : data.valueOF === "Date" ? (
											<h3 className="text-14-26 ml-2 d-inline-block text-right col-3">
												{data.default}
											</h3>
										) : null}
										{data.rightdescription !== ""
											? data.rightdescription
											: null}
									</p>
								</div>
							);
						} else {
							console.log("inside else condition")
							return (
								<div
									className={
										data.title === ""
											? "d-flex flex-column align-items-start"
											: "d-flex flex-column align-items-start mt-3"
									}
								>
									<p className="text-14-normal mb-2">{data?.isDynamicTitle ? data.title+" " + props.actionTitle: data.title }</p>
									{_value.length > 0 ? (
										_value[0].value == "" ? (
											<h3 className="text-14 highlight mb-2 text-center bg-white">
												{data.default}
											</h3>
										) : _value[0].type == "ck-editor" ? (
											<strong
												className="text-14-26 d-inline-block text-right ml-2"
												style={{
													lineHeight: "unset",
													marginLeft: "0.1rem",
													marginTop: "0.5rem",
													paddingBottom: 0,
													marginBottom: 0,
												}}
												dangerouslySetInnerHTML={{ __html: _value[0].value }}
											/>
										) : (
											<strong className="text-14 mt-0 mb-0 pb-0">
												{_value[0].value}
											</strong>
										)
									) : null}
								</div>
							);
						}
					}
				})}

				<div className="d-flex letterBottomView">{props.horizontal}</div>
			</div>
		</>
	);
};

export default GeneratedOption;
