import React, { useEffect, useState } from 'react'
import DropdownContainer from '../dropdown'
import { ChartImage, CouncilDesktop, CouncilMobile, HousingAssociateDesktop, HousingAssociateMobile, LandlordDesktop, LandlordMobile, LettingAgentDesktop, LettingAgentMobile } from '../../config/images';
import { Link } from 'react-router-dom';

const Chart = (props) => {

  const chartData = [{
    id: "1",
    category: 'Housing association',
    img: HousingAssociateDesktop,
    imgMobile: HousingAssociateMobile

  }, {
    id: "2",
    category: 'Council',
    img: CouncilDesktop,
    imgMobile: CouncilMobile

  }, {
    id: "3",
    category: 'Letting agent',
    img: LettingAgentDesktop,
    imgMobile: LettingAgentMobile

  }, {
    id: "4",
    category: 'Private landlord',
    img: LandlordDesktop,
    imgMobile: LandlordMobile,

  }


  ]

  const [dropdownVal, setDropDownVal] = useState();
  const [dropdDownId, setDropDownId] = useState("4");
  const [chartImage, setChartImage] = useState('');
  const [mobileImage, setmMobileImage] = useState('');

  const onDropDownClick = (val, id) => {
    setDropDownVal(val);
    setDropDownId(id);
  }


  useEffect(() => {
    const filter = chartData.filter((item) => item.id === dropdDownId);
    setChartImage(filter[0].img);
    setmMobileImage(filter[0].imgMobile)
  }, [dropdDownId])


  return (
    <div className='chart bg-white'>
      <div className='container'>
        <h3 className='title lightGrey'>Helping you navigate to the right person to talk to</h3>
        <DropdownContainer title={'I’m renting with'} colorClass={'bg-white'} color="lightBlue" dropdownvalue={dropdownVal ? dropdownVal : props.dropdown['3']?.value} options={props.dropdown} onDropDownChange={onDropDownClick} />
        <img src={chartImage} alt={dropdownVal} className='chart-image d-none d-md-block' />
        <div className='chart-mobile-container'>
          <img src={mobileImage} alt={dropdownVal} className='chart-image d-block d-md-none' />
        </div>
        <div className='content-container col-12 col-md-8 col-lg-7' >
          <h3 className='title darkGrey '>We know it’s confusing</h3>
          <p className='description text-normal darkGrey inter-font'>We know it’s confusing about who to talk to and the order to do it in, that’s why we created a helpful tool to analyse your situation and provide you with information.</p>
          <Link className="button" to="/get-advice">Create a personalised plan</Link>
        </div>
      </div>
    </div>
  )
}

export default Chart
