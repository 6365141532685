import React from 'react'
import QuestionTitle from './questionTitle'

const NoticeCard = (props) => {

  return (
    <div className='notice-card'>
      <QuestionTitle question={props.title} />
      <p className='bg-white description' dangerouslySetInnerHTML={{__html: props.description}}/>
      
    </div>
  )
}

export default NoticeCard
