import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ToolTip from '../../assets/images/toolTip.svg'
import DatePicker from 'react-datepicker';
import moment from 'moment';


const CustomInputColumn = ({_key, label, short_descp, type, value, onChange, _className, placeholder,isValid, toolTip }) => {
  const inputClass = !isValid ? 'inputError' : '';
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState(null);
 
  const handleChange = (event,type) => {
    
    let inputValue = type === 'date' ? moment(event).format('DD/MM/YYYY') : event.target.value;
    let errorMsg = '';

    if (inputValue.length > 100) {
      errorMsg = 'Maximum character limit exceeded';
    } else {
      switch (type) {
        case 'number':
          inputValue = inputValue.replace(/\D/g, '');
          break;
        case 'email':
          if (!validateEmail(inputValue)) {
            errorMsg = 'Invalid email format';
          }
          break;
        case 'text':
            // Remove special characters except for allowed ones
            inputValue = inputValue;
            break;
        case 'tel':
            if (!validateTelephone(inputValue)) {
              errorMsg = 'Invalid telephone number';
            }
            break;
        default:
          break;
      }
    }

    setError(errorMsg);
    onChange(inputValue);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const validateTelephone = (tel) => {
    // Remove non-digit characters
    const telDigits = tel.replace(/\D/g, '');
    const telLength = telDigits.length;

    // Check if the length is between 10 and 11 digits
    if (telLength < 10 || telLength > 11) {
      return false;
    }

    return true;
  };

  return (
    <>
    <div>
     <p className={short_descp != '' ? `text-tittle-19 mb-0 ${_className}`: `text-tittle-19 ${_className}`}>
      {label} 
      {toolTip !== '' ? (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip id="button-tooltip-2">
                    {toolTip}
                </Tooltip>
            }
        >
            <img className="icon-hint" style={{width: '1.063rem', height: '1.063rem', marginLeft: '0.5rem'}} src={ToolTip} alt="info" />
        </OverlayTrigger>
      ) : null}
      </p>

      </div>
     {short_descp && <p className='text-16 editor-description' dangerouslySetInnerHTML={ {__html: short_descp} }  />}
      <>
      {type === 'date' ?
      
        <DatePicker
        portalId="date-container"
        placeholderText="DD/MM/YYYY"
        
        value={value !== "" ? moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''}
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={(date) => { handleChange(date, type); setStartDate(date) }} 
        popperClassName='calendar-container'
        popperPlacement="bottom-start"
        popperModifiers={{
          flip: {
            enabled: false // don't allow it to flip to be above
          },
          preventOverflow: {
              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
              enabled: false // turn off since needs preventOverflow to be enabled
          }
      }}
      />
      
      : 

      label === 'Amount charged' || label === 'Cost of rent per month' || label === 'Cost of the deposit' ? (
        <>
          <span class="currencyinput"
            style={{width: '80%', display: 'inline-block', fontSize: '1.125rem', border: '0.094rem solid #000', padding: '0.5rem 0.813rem'}}
          >
            £
            <input
              type={type}
              className={`input inputFieldView text-18 ${inputClass}`}
              maxLength={200}
              placeholder={placeholder}
              onChange={(e)=>handleChange(e,type)}
              value={value}
              style={{ border: 0, padding: 0, boxShadow: 'none', outline: 'none'}}
              
            />
          </span>
        </>
      ) :
      
      (

       
      <input
        type={type}
        className={`input inputFieldView text-18 ${inputClass}`}
        maxLength={200}
        placeholder={placeholder}
        onChange={(e)=>handleChange(e,type)}
        value={value}
      />
    )}
      </>
      {error && <p className='error-text' style={{ color: 'red' }}>{error}</p>}
    </>
  );
};

CustomInputColumn.propTypes = {
  _key: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'number', 'text', 'ck-editor']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  _className: PropTypes.string,
  placeholder: PropTypes.string,
  selected: PropTypes.bool,
  short_descp: PropTypes.string,
};

export default CustomInputColumn;
