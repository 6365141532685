import React from 'react'

const TopTip = (props) => {
  return (
    <div className='top-tip'>
      <h3>Top tip</h3>
      {props.templateType === 'tel_councilReportHarassment' ||  props.templateType === 'tel_councilEvictionNotice' ? (
       <>
       <ul>
          <li><p>Remember to speak slowly, clearly, and calmly. </p></li>
          <li><p>If you leave a voicemail, leave your name and telephone number at the beginning of the message, and repeat it at the end. </p></li>
         </ul>
         <p>You can use these prompts to phone the council if you have received an eviction notice and could become homeless.<br/><strong>It can be hard to get through to the council, so keep trying. Ask to speak to the homelessness officer. </strong></p>  
        
        </>
      ) : (
      <ul className='mb-0'>
        <li><p>Know your schedule so you can agree a time for them to inspect the property if necessary. </p></li>
        <li><p>Remember to speak slowly, clearly, and calmly.</p></li>
        <li><p>If you leave a voicemail, leave your name and telephone number at the beginning of the message, and repeat it at the end. </p></li>
        <li><p>Be prepared to take notes of what the landlord / letting agent says so you have a record of your conversation.</p></li>
      </ul>
    ) }
    </div>
  )
}

export default TopTip
