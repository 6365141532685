import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = () => {
  return (
    <div className='d-flex justify-content-between breadcrumb'>
      <div>
        <Link to="/" className='d-none d-md-inline-block pe-5 home' >Home</Link>
        <span>Get help for your issue</span>
      </div>
      <Link to="/">Exit</Link>
    </div>
  )
}

export default Breadcrumb
